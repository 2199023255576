import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { User } from 'src/app/data-types/user.object';
import * as Constants from '../../shared/constants';
import { FormService } from '../form/form.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userObj = new User();
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(this.userObj);
  private awaitResp = false;
  private authkey = '';
  constructor(
    private authServ: AuthService,
    private formServ: FormService,) {
    this.init();
  }
  async init() {
    // console.log('init user service');
    this.authkey = await this.authServ.getAuthkey();
    if (this.authkey) {
      console.log(this.authkey);
      this.getUserProfile();
    }
  }
  async getUserProfile() {
    while (this.awaitResp) {
      await new Promise(resolve => setTimeout(resolve, 500));
    }
    this.awaitResp = true;
    if (!this.userObj.mobile || this.userObj.mobile.toString().length < 7) {
      await this.getUserProfileFromServer();
    } else {
      console.log('return local');
    }
    this.awaitResp = false;
    return this.userObj;
  }
  async getUserProfileFromServer() {
    // console.log('getting from server');
    const url = Constants.USER_API_PATH + 'profile';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.user) {
      this.userObj = resp.user;
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
    }
    return this.userObj;
  }
  async getOtp(login) {
    var url = Constants.PUB_API_PATH + '/send_otp';
    const resp = await this.formServ.httpPost(login, url);
    return resp;
  }
  async verifyOtp(login) {
    var url = Constants.PUB_API_PATH + '/verify_otp';
    const resp = await this.formServ.httpPost(login, url);
    this.getUserProfileFromServer();
    return resp;
  }
  async logout() {
    this.userObj = new User();
    this.user.next(this.userObj);
    this.authServ.clear();
  }


  async ClientCountmsg() {
    const url = Constants.API_PATH + 'count_msg';
    const resp = await this.formServ.httpPost('', url);
    return resp.countmsg;
  }
  async allmessage() {
    const url = Constants.USER_API_PATH + 'get_msg';
    const resp = await this.formServ.httpPost('', url);
    return resp.messages;
  }

  async sendMessage(msg) {
    const url = Constants.USER_API_PATH + 'msg';
    const data = { m: msg };
    const resp = await this.formServ.httpPost(data, url);
    return resp;
  }
}

