export class User {
    address = "";
    cate_id = "";
    city = "";
    company = "";
    mobile = "";
    created = "";
    email = "";
    gst = "";
    gst_date = "";
    id = "";
    last_seen_message = "";
    last_seen_notification = "";
    loggedIn = false;
    name = "";
    otp = "";
    paid_extra = "";
    pincode = "";
    state = "";
    status = "";
    title = "";
    payment_methods = "";
}
