import { Component } from '@angular/core';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { Cart } from 'src/app/data-types/cart.object';
import { OrderService } from 'src/app/services/order/order.service';
import { ProductService } from 'src/app/services/product/product.service';
import { UserService } from 'src/app/services/user/user.service';
import { FormService } from 'src/app/services/form/form.service';
import { Browser, OpenOptions } from '@capacitor/browser';

@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.page.html',
  styleUrls: ['./my-cart.page.scss'],
})
export class MyCartPage {
  cart: Cart = new Cart();
  prodIds: any = [];
  products: any = {};
  payModes: any = {};
  payModeInputs: any = [];
  user: any;
  userPayments = [];
  shippingCost = 0;
  totalAmount = 0;
  constructor(
    private navCtrl: NavController,
    private prodServ: ProductService,
    private alertCtrl: AlertController,
    private orderServ: OrderService,
    private modalCtrl: ModalController,
    private userServ: UserService,
    private formServ: FormService
  ) {
  }
  // tslint:disable-next-line: use-life-cycle-interface
  async ngOnInit() {
    this.user = await this.userServ.getUserProfile();
    try {
      this.userPayments = JSON.parse(this.user.payment_methods);
    } catch (e) {
      this.userPayments = [];
    }
    console.log(this.userPayments);
    await this.allproducts();
    this.getPaymentModes();
    await this.orderServ.cart.subscribe(async c => {
      this.cart = c;
      this.prodIds = Object.keys(this.cart.items);
      this.calcShippingCost();
    });
  }

  async calcShippingCost() {
    let totalWeight = 0;
    this.prodIds.forEach(pid => {
      totalWeight += this.products[pid].prod_weight * this.cart.items[pid];
      this.totalAmount += this.products[pid].final_amt * this.cart.items[pid];
    });
    this.shippingCost = Math.ceil(totalWeight / 500) * 50;
    this.totalAmount += this.shippingCost;
  }

  async allproducts() {
    const prods = await this.prodServ.getProducts();
    const that = this;
    prods.forEach((i, j) => {
      that.products[i.prod_id] = i;
    });
  }
  async getPaymentModes() {
    console.log(this.user);
    const pms = await this.orderServ.getPaymentModes();
    this.payModeInputs = [];
    pms.forEach(pm => {
      if (this.userPayments.length == 0 || this.userPayments.indexOf(parseInt(pm.pmode_id)) > -1) {
        this.payModes[pm.pmode_id] = pm;
        this.payModeInputs.push({
          name: 'payOpt',
          label: pm.pmode_title,
          value: pm.pmode_id.toString(),
          type: "radio"
        });
      }
    });
  }
  addToCart(productId) {
    this.orderServ.addToCart(productId, 1);
  }
  removeFromCart(productId) {
    this.orderServ.removeFromCart(productId);
  }
  async selectPaymentMode() {
    if (this.payModeInputs.length == 1) {
      this.orderServ.setPaymentMode(this.payModeInputs[0].value);
      this.openPaymentOption(this.payModeInputs[0].value);
    } else {
      let alert = await this.alertCtrl.create({
        message: 'Please select your payment option',
        inputs: this.payModeInputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Confirm',
            cssClass: 'alert-btn-success',
            handler: (data) => {
              this.orderServ.setPaymentMode(data);
              this.openPaymentOption(data);
            }
          }
        ]
      });
      alert.present();
    }
  }
  async openPaymentOption(payId) {
    console.log(payId);
    if (payId == 1) {
      this.callPaymentGateway();
    } else {
      this.popupOfflinePaymentInfo();
    }
  }
  async popupOnlinePayment() {
    let alert = await this.alertCtrl.create({
      message: 'Online payments are not available at the moment. Please select another option',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.selectPaymentMode();
          }
        }
      ]
    });
    alert.present();
  }
  async popupOfflinePaymentInfo() {
    let alert = await this.alertCtrl.create({
      message: this.payModes[this.cart.payMode].pmode_desc + '<br>To see all our payment details press the <strong>Menu</strong> button & go to <strong>Payment Details</strong> page.<br> Confirm to pay & place order.',
      inputs: [{
        name: 'note',
        placeholder: 'Order Note',
        type: "textarea"
      }],
      buttons: [
        {
          text: 'back',
          handler: () => {
            this.selectPaymentMode();
          }
        },
        {
          text: 'Order Now',
          handler: (data) => {
            this.order(data.note);
          }
        }
      ]
    });
    alert.present();
  }
  async order(note) {
    const orderResult = await this.orderServ.order(note);
    console.log(orderResult);
    if (orderResult) {
      const msg = orderResult.message ? orderResult.message : 'Your order has been placed successfully.'
      let alert = await this.alertCtrl.create({
        message: msg,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.dismissModal();
              this.navCtrl.navigateForward('/orders');
            }
          }
        ]
      });
      alert.present();
    }
  }
  async showAlert() {

  }
  async dismissModal() {
    this.modalCtrl.dismiss();
  }


  async callPaymentGateway() {
    const orderResult = await this.orderServ.order("");
    // console.log(orderResult);
    const browserOptions: OpenOptions = {
      url: this.formServ.BASE_URL + "payments/request_transaction/" + orderResult.orderid,
      windowName: "GeeBeesOnlinePayment",
      toolbarColor: "#ffd700",
      presentationStyle: 'popover'
    };
    Browser.addListener("browserPageLoaded", () => {

    });
    Browser.addListener("browserFinished", async () => {
      const resp = await this.orderServ.payDetailefromserver(orderResult.orderid);
      if (resp.status == 1) {
        let alert = await this.alertCtrl.create({
          message: resp.resp.pres_desc,
          buttons: [
            {
              text: 'OK',
              handler: () => {
                this.dismissModal();
                this.navCtrl.navigateForward('/orders');
              }
            }
          ]
        });
        alert.present();
      }
    });
    await Browser.open(browserOptions);
  }


}
