import { Injectable } from '@angular/core';
import { FormService } from '../form/form.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  slides = [];
  cart: any = {};
  orders = [];
  orderDetails: any = { payment_mode: 0 };
  constructor(
    private formServ: FormService,
  ) { }

  async getSlides(section) {
    if (this.slides.length > 0) {
      return this.slides;
    }
    const url = 'pub/get_slides/' + section;
    const httpResp = await this.formServ.httpPost({}, url);
    if (httpResp.slides) {
      this.slides = httpResp.slides;
      this.slides.forEach(s => {
        if (s.slide_action) {
          console.log(s.slide_action);
          s.slide_action = JSON.parse(s.slide_action);
        }
        s.slide_image = this.formServ.UPLOADS + s.slide_image;
      });
      return this.slides;
    } else {
      return [];
    }
  }
  async productTagsForSlides(section) {
    const url = 'pub/section_slides/' + section;
    const httpResp = await this.formServ.httpPost({}, url);
    if (httpResp.slide_sections && httpResp.slide_sections.length > 0) {
      return httpResp.slide_sections;
    } else {
      return [];
    }
  }
  async states() {
    const url = 'pub/get_states/';
    const httpResp = await this.formServ.httpPost({}, url);
    if (httpResp.states) {
      return httpResp.states;
    } else {
      return [];
    }
  }



}
