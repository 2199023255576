export class ProdFilter {
    search: "";
    cat: [];
    flavor: [];
    packing: [];
    sugar: [];
    type: [];
    catOptions: [];
    flavorOptions: [];
    packingOptions: [];
    sugarOptions: [];
    typeOptions: [];
}
