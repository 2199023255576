import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        FormsModule
    ],
    declarations: [MenuComponent, FooterComponent, LoginComponent, TopBarComponent, UserMenuComponent],
    exports: [MenuComponent, FooterComponent, LoginComponent, TopBarComponent, UserMenuComponent]
})
export class ComponentsModule { }