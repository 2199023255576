import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { User } from 'src/app/data-types/user.object';
import { FormService } from 'src/app/services/form/form.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  buttons: any = [];
  user: any = new User;
  appMenu: any = [
    { url: '/dashboard', icon: 'home', title: 'Dashboard' },
    { url: '/products', icon: 'cube', title: 'Products' },
    { url: '/profile', icon: 'person', title: 'My Profile' },
    { url: '/my-order', icon: 'gift', title: 'My Orders' },
    { url: '/message', icon: 'mail', title: 'Messages' },
    { url: '/contact', icon: 'call', title: 'Contact' },
  ]
  constructor(
    private formServ: FormService,
    private userServ: UserService,
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    this.getContactButtons();
    await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
  }
  async getContactButtons() {
    var url = 'pub/menu_footer_contacts';
    const resp = await this.formServ.httpPost({}, url, false);
    this.buttons = resp.menu;
  }
  async logout() {
    this.userServ.logout();
    this.navCtrl.navigateRoot('/');
  }
}
