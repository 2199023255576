import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProdFilter } from 'src/app/data-types/prodfilter.object';
import { FormService } from '../form/form.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  slides = [];
  cart: any = {};
  orders = [];
  orderDetails: any = { payment_mode: 0 };
  products = [];
  private filterObj = new ProdFilter();
  public filter: BehaviorSubject<ProdFilter> = new BehaviorSubject<ProdFilter>(this.filterObj);
  constructor(
    private formServ: FormService,
  ) {
    this.getFilter();
  }

  async getProducts() {
    if (this.products.length == 0) {
      this.products = await this.getProductsFromApi();
    }
    return this.products;
  }
  async getTagProducts(tag) {
    if (this.products.length == 0) {
      this.products = await this.getProductsFromApi();
    }
    let catProducts = [];
    this.products.forEach((p) => {
      if (p.prod_desc.toLowerCase().indexOf(tag) >= 0) {
        catProducts.push(p);
      }
    });
    return catProducts;
  }
  async getProductsFromApi() {
    const url = 'pub/products/';
    const httpResp = await this.formServ.httpPost({}, url);
    if (httpResp.products && httpResp.products.length > 0) {
      this.products = httpResp.products;
      this.products.forEach(p => {
        p.prod_image = this.formServ.UPLOADS + p.prod_image;
      });
      return this.products;
    } else {
      return [];
    }
  }
  async getFilter() {
    const url = 'pub/filter_data/';
    const httpResp = await this.formServ.httpPost({}, url);
    this.filterObj.catOptions = httpResp.categories;
    this.filterObj.flavorOptions = httpResp.flavours;
    this.filterObj.packingOptions = httpResp.packingtypes;
    this.filterObj.typeOptions = httpResp.types;
    this.filterObj.sugarOptions = httpResp.sugars;
    this.filter.next(this.filterObj);
    this.filterObj;
  }
  /* backword compatibility * /

  /*Function for Filter the product-Start* /
  async setFilter(filterData) {
    //this.filterData = filterData;
    //this.storage.set('filterdata', JSON.stringify(filterData));
  }
  async getFilter() {
    const url = 'pub/filter_data/';
    const httpResp = await this.formServ.httpPost({}, url);
    // console.log(httpResp);
    return httpResp;
  }
  /*End*/

  /*Function Product list on main page-Start*/
  // async getProducts() {
  //   const url = 'pub/products/';
  //   const httpResp = await this.formServ.httpPost({}, url);
  //   console.log(httpResp);
  // }
  /*End*/

  /*------Function for cart data remove cart data----Start* /
  async getCart() {
    const url = 'pub/filter_data/';
    const httpResp = await this.formServ.httpPost({}, url);
    console.log(httpResp);

  }
  removeFromCart(productId) {
    if (typeof this.cart[productId] == 'undefined') {
      return this.cart;
    } else if (this.cart[productId] > 1) {
      this.cart[productId] -= 1;
    } else {
      delete this.cart[productId];
    }
    //this.storage.set('cart', JSON.stringify(this.cart));
    return this.cart;
  }

  async changeQty(productId, qty) {
    if (typeof this.cart[productId] == 'undefined') {
      return this.cart;
    } else if (this.cart[productId] <= 0) {
      delete this.cart[productId];
    } else {
      this.cart[productId] = qty;
    }
    // this.storage.set('cart', JSON.stringify(this.cart));
    return this.cart;
  }
  /*-------End-----* /
  //Client order related function.......
  async myorders(past) {
    if (past != 'past-orders') {
      past = '';
    }
    console.log(past);

    const url = 'pub/orders/' + past;
    const httpResp = await this.formServ.httpPost({}, url);
    this.orders = httpResp.orders;
    return this.orders;
  }

  async orderdata() {
    const url = 'pub/get_orders/';
    const resp = await this.formServ.httpPost('', url);
    this.orders = resp.orderdata;
    return this.orders;
  }

  async cancelorder(orderid) {
    const url = 'pub/get_ordecancelorderrs/' + orderid;
    const resp = await this.formServ.httpPost('', url);
    console.log(resp);
  }
  async order() {
    const url = 'pub/order_products/';
    let data = { cart: JSON.stringify(this.cart), details: JSON.stringify(this.orderDetails) };
    const resp = await this.formServ.httpPost(data, url);
    if (resp.status == 200) {
      this.cart = {};
      // this.storage.set('cart', this.cart);
      //this.navCtrl.navigateRoot('/dashboard');
    }
    return this.cart;
  }
  /*-------End-----*/
  /*Add to Cart function---start* /
  addToCart(productId, qty) {
    if (typeof this.cart[productId] !== 'undefined') {
      this.cart[productId] += qty;
    } else {
      this.cart[productId] = qty;
    }
    // this.storage.set('cart', JSON.stringify(this.cart));
    return this.cart;
  }
  /*----end-----*/

  // async getProductsFromApi() {
  //   const url = 'pub/products/';
  //   const resp = await this.formServ.httpPost('', url);
  //   this.products = resp.products;
  //   return this.products;
  // }


}
