import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/data-types/user.object';
import { ModalController, NavController } from '@ionic/angular';
import { FilterPage } from 'src/app/pages/filter/filter.page';
import { ProdFilter } from 'src/app/data-types/prodfilter.object';
import { ProductService } from 'src/app/services/product/product.service';
import { MyCartPage } from 'src/app/pages/my-cart/my-cart.page';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  exploreOpen = false;
  memberOpen = false;
  searchOpen = false;
  user: User = new User();
  filter: ProdFilter = new ProdFilter();
  constructor(
    private userServ: UserService,
    private modalCtrl: ModalController,
    private prodServ: ProductService,
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
    await this.prodServ.filter.subscribe(async f => {
      this.filter = f;
    });
  }

  async openFilter() {
    const modal = await this.modalCtrl.create({
      component: FilterPage,
    });
    // modal.onDidDismiss().then((resp) => {
    // }).catch((err) => console.log(err.message));
    return await modal.present();
  }
  async openCart() {
    const modal = await this.modalCtrl.create({
      component: MyCartPage,
    });
    // modal.onDidDismiss().then((resp) => {
    // }).catch((err) => console.log(err.message));
    return await modal.present();
  }
  updateFilter() {
    this.prodServ.filter.next(this.filter);
    this.navCtrl.navigateForward('/products');
  }
}
