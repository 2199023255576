import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authkey = '';
  constructor() {
    this.getAuthkey();
  }

  async setAuthkey(auth) {
    this.authkey = auth;
    await Storage.set({
      key: 'tssAuthkey',
      value: auth
    });
  }
  async getAuthkey() {
    if (!this.authkey) {
      // console.log('getting authkey from storage');
      const { value } = await Storage.get({ key: 'tssAuthkey' });
      this.authkey = value;
    }
    // console.log('Authkey: ', this.authkey);
    return this.authkey;
  }
  async clear() {
    // console.log('clearing storage');
    await Storage.clear();
    this.authkey = '';
  }

  // other storage methods
  // async removeItem() {
  //   await Storage.remove({ key: 'name' });
  // }
  // async keys() {
  //   const { keys } = await Storage.keys();
  //   console.log('Got keys: ', keys);
  // }


}
