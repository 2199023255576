import { Component, OnInit } from '@angular/core';
import { FormService } from '../../services/form/form.service';
import * as Constants from '../../shared/constants';
import { ModalController, NavController } from '@ionic/angular';
import { ContentService } from 'src/app/services/content/content.service';
import { ProdFilter } from 'src/app/data-types/prodfilter.object';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterPage {
  filter: ProdFilter = new ProdFilter;
  constructor(
    private modalCtrl: ModalController,
    private formServ: FormService,
    private prodServ: ProductService,
    private contentSrv: ContentService,
    private navCtrl: NavController) {
  }
  filterform() {
    this.prodServ.filter.next(this.filter);
    this.navCtrl.navigateForward('/products');
    this.dismissModal();
  }

  async ngOnInit() {
    await this.prodServ.filter.subscribe(async f => {
      this.filter = f;
    });
  }
  async dismissModal() {
    this.modalCtrl.dismiss();
  }
}
