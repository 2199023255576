import { Injectable } from '@angular/core';
import { Platform, LoadingController, AlertController, NavController } from '@ionic/angular';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
// import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import * as Constants from '../../shared/constants';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
// import { UrlSerializer, DefaultUrlSerializer } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  BASE_URL = '';
  BASE_API_URL = '';
  UPLOADS = '';
  authkey = '';
  i = 0;
  loadingElements = [];
  constructor(
    private plt: Platform,
    private authServ: AuthService,
    private http: HttpClient,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private router: Router
  ) {
    // console.log(window.location.href);
    // console.log(window.location.href.indexOf('localhost'));
    if (window.location.href.indexOf('localhost') < 0
      || this.plt.is('android') || this.plt.is('ios')
      // || plt.is('cordova')
    ) {
      this.BASE_URL = Constants.EXT_URL;
    } else {
      this.BASE_URL = Constants.INT_URL;
    }
    this.BASE_API_URL = this.BASE_URL + Constants.API_PATH;
    this.UPLOADS = this.BASE_URL + Constants.UPLOAD_PATH;
    this.init();
  }
  async init() {
    this.authkey = await this.authServ.getAuthkey();
  }

  async httpPost(formValues, url, showLoading = true) {
    this.i++;
    const li = this.i;
    if (showLoading) {
      await this.presentLoading(li);
    }
    url = this.BASE_API_URL + url;

    // send form-urlencoded
    const params = new HttpParams({
      fromObject: formValues,
    });
    let resp: any = {};
    // send multipart form data
    // const formData = new FormData();
    // formData.append('aaaa', 'bbbb');
    // const keys = Object.keys(formValues);
    // const values = Object.values(formValues);
    // keys.forEach((k, i) => {
    //   console.log('key:' + k + ', val:' + values[i]);
    //   formData.append(k, values[i].toString());
    // });
    // console.log(formData);

    const httpOptions = {
      headers: {
        // 'Content-Type': 'application/json', // send json body
        'Content-Type': 'application/x-www-form-urlencoded', // send form data
        Authkey: this.authkey ? this.authkey : '',
        Version: Constants.VERSION,
        Source: 'app',
      },
      observe: 'response' as 'response', // get full resp. default is only body
      params: {} // url params
    };

    resp = await this.http.post(url, params, httpOptions)
      .toPromise()
      .then(async httpResp => {
        // console.log(httpResp);
        switch (httpResp.status) {
          case 200:
            let respBody: any = httpResp.body;
            respBody.status = 200;
            if (showLoading && respBody.msg) {
              this.presentAlert('', respBody.msg, 'Success');
            }
            if (respBody.authkey) {
              this.authServ.setAuthkey(respBody.authkey);
              this.authkey = respBody.authkey;
            }
            return respBody;
            break;
          default:
            // always inform server about the error
            // may be by calling another error reporting api
            // else by storing log sending to sserver by an error reporting service
            // console.log(httpResp);

            const httpErrResp: any = httpResp;
            const error = httpErrResp.error.error ? httpErrResp.error.err : 'Unidentified response, contact DIBC team.';
            this.presentAlert(
              'Code: ' + status,
              error
              // <br>details: '+JSON.stringify(httpErrResp)
            );
            break;
        }
        resp = 1;
      }).catch(httpErrResp => {
        switch (httpErrResp.status) {
          case 500:
            // always inform server about the error
            // may be by calling another error reporting api
            // else by storing log sending to sserver by an error reporting service
            this.presentAlert('Server Error');
            break;
          case 404:
            // always inform server about the error
            // may be by calling another error reporting api
            // else by storing log sending to server by an error reporting service
            this.presentAlert('API Error');
            break;
          case 401:
            this.presentAlert('Authorization Error', 'Please logout & login again or contact system administrator');
            this.authServ.clear();
            this.navCtrl.navigateRoot('/');
            break;
          default:
            // always inform server about the error
            // may be by calling another error reporting api
            // else by storing log sending to sserver by an error reporting service
            const error = httpErrResp.error.err ? httpErrResp.error.err : 'Unidentified error, contact DIBC team.';
            this.presentAlert(
              'ErrorCode: ' + httpErrResp.status,
              error
              // <br>details: '+JSON.stringify(httpErrResp)
            );
            break;
        }
        return false;
      });

    if (showLoading) {
      this.loadingElements[li].dismiss();
    }
    return resp;
  }
  async presentLoading(i) {
    this.loadingElements[i] = await this.loadingCtrl.create({
      message: 'Please wait...',
      spinner: 'crescent'
      // duration: 2000
    });
    return await this.loadingElements[i].present();
  }
  async presentAlert(status, msg = '', title = 'Error', btns: any = ['Ok']) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: status,
      message: msg,
      buttons: btns
    });
    return await alert.present();
  }
}
