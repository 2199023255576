import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { BehaviorSubject, from } from 'rxjs';
import * as Constants from '../../shared/constants';
import { Cart } from 'src/app/data-types/cart.object';
import { FormService } from '../../services/form/form.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private cartObj = new Cart();
  public cart: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(this.cartObj);

  orders: any = [];
  pid: any = '';
  catProductList = {};

  totalWt: any = {};
  totalQty: any = {};

  categories = [];

  payModes: any = {};

  constructor(
    private formServ: FormService) {
    this.getCart();
  }


  async getCartFromStorage() {
    const { value } = await Storage.get({ key: 'cart' });
    if (value && value.length > 0) {
      try {
        this.cartObj.items = JSON.parse(value);
      } catch (e) {
        this.cartObj = new Cart();
      }
      await this.calcTotal();
    }
    this.cart.next(this.cartObj);
    return this.cartObj;
  }
  async getCart() {
    if (this.cartObj && Object.keys(this.cartObj.items).length > 0) {
      return this.cartObj;
    } else {
      await this.getCartFromStorage();
    }
    await this.calcTotal();
    return this.cartObj;
  }

  async setCart(cartObj) {
    this.cartObj = cartObj;
    Storage.set({ key: 'cart', value: JSON.stringify(this.cartObj.items) });
    this.cart.next(this.cartObj);
    await this.calcTotal();
  }
  async addToCart(productId, qty) {
    if (typeof this.cartObj.items[productId] !== 'undefined') {
      this.cartObj.items[productId] += qty;
    } else {
      this.cartObj.items[productId] = qty;
    }
    this.setCart(this.cartObj);
    await this.calcTotal();
    return this.cartObj;
  }
  async removeFromCart(productId) {
    if (typeof this.cartObj.items[productId] == 'undefined') {
      return this.cartObj.items;
    } else if (this.cartObj.items[productId] > 1) {
      this.cartObj.items[productId] -= 1;
    } else {
      delete this.cartObj.items[productId];
    }
    this.setCart(this.cartObj);
    await this.calcTotal();
    return this.cartObj;
  }
  async setPaymentMode(pm) {
    this.cartObj.payMode = pm;
    this.setCart(this.cartObj);
  }
  async changeQty(productId, qty) {
    if (typeof this.cartObj.items[productId] == 'undefined') {
      return this.cartObj;
    } else if (this.cartObj.items[productId] <= 0) {
      delete this.cartObj.items[productId];
    } else {
      this.cartObj.items[productId] = qty;
    }
    this.setCart(this.cartObj)
    await this.calcTotal();
    return this.cartObj;
  }
  async order(orderNote) {
    const url = Constants.USER_API_PATH + 'order_products';
    let data = {
      cart: JSON.stringify(this.cartObj),
      note: orderNote
    };
    const resp = await this.formServ.httpPost(data, url);
    if (resp.status == 200) {
      this.cartObj = new Cart();
      this.setCart(this.cartObj);
      await this.calcTotal();
      return resp;
    } else {
      return false;
    }
  }

  async cancelorder(orderid) {
    const url = Constants.USER_API_PATH + 'cancel_order/' + orderid;
    const resp = await this.formServ.httpPost('', url);
  }
  async getOrders() {
    const url = Constants.USER_API_PATH + 'get_orders/';
    const resp = await this.formServ.httpPost('', url);
    this.orders = resp.orders;
    return this.orders;
  }
  async getOrderDetails(orderid) {
    const url = Constants.USER_API_PATH + 'orderDetails/' + orderid;
    const resp = await this.formServ.httpPost('', url);
    return resp.orderproducts;
  }
  async prodDetailefromserver(prodid) {
    const url = Constants.USER_API_PATH + 'orderProduct/' + prodid;
    const resp = await this.formServ.httpPost('', url);
    return resp.productdtls;
  }
  async payDetailefromserver(orderid) {
    const url = Constants.USER_API_PATH + 'check_payment/' + orderid;
    const resp = await this.formServ.httpPost('', url, false);
    return resp;
  }

  async resetTotal() {
    this.totalQty = 0;
  }

  async calcTotal() {
    await this.resetTotal();
    const prodIds = Object.keys(this.cart);
    prodIds.forEach((p) => {
      this.totalQty += this.cartObj.items[p] * 1;
    });
  }
  async getPaymentModes() {
    const url = 'pub/payment_modes/';
    const httpResp = await this.formServ.httpPost({}, url);
    if (httpResp.paymodes && httpResp.paymodes.length > 0) {
      httpResp.paymodes.forEach(pm => {
        this.payModes[pm.pmode_id] = pm;
      });
      return httpResp.paymodes;
    } else {
      return [];
    }
  }
}
